const routes = [
  { link: "/", name: "Home" },
  { link: "/testimony", name: "Testimony" },
  { link: "/developers", name: "Developers(CEDO)" },
  { link: "/our-story", name: "Our Story" },
  { link: "https://cefcodev.vercel.app/", name: "Foundation" },
  { link: "/workshop", name: "Workshop" },
];

export default routes;
