// import { Zoom } from "react-reveal";

// const TeamCard = ({ photo, name, job }) => {
//   return (
//     <Zoom>
//       <div className="w-[280px] z-0  h-38 rounded-md relative mb-10">
//         <div className="aspect-[4/5] w-full relative overflow-hidden">
//           <img
//             src={require(`../../assets/img/team/${
//               photo || "/placeholder.svg"
//             }`)}
//             alt="team member"
//             className="object-cover object-top w-full h-full rounded-md"
//           />
//           <div className="p-4 rounded-md z-10 absolute left-0 -bottom-10 bg-white w-3/4 shadow-xl">
//             <p className="font-medium text-xl text-[#211F54] capitalize">
//               {name}
//             </p>
//             <p className="text-sm text-[#031A6E] uppercase">{job}</p>
//           </div>
//         </div>
//       </div>
//     </Zoom>
//   );
// };

// export default TeamCard;

import { Zoom } from "react-reveal";

const TeamCard = ({ photo, name, job }) => {
  return (
    <Zoom>
      <div className="w-[280px] rounded-md relative">
        <div className="aspect-[4/5] w-full relative overflow-hidden">
          <img
            src={require(`../../assets/img/team/${
              photo || "/placeholder.svg"
            }`)}
            alt="team member"
            className="w-full h-full object-cover rounded-t-md"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-[#fff] text-[#000D83] p-4">
            <p className="font-medium text-xl text-[#000D83] capitalize">
              {name}
            </p>
            <p className="text-sm italic text-[#000D83]">{job}</p>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

export default TeamCard;
