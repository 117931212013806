const team = [
  {
    id: 1,
    name: "Odunayo Oluwole",
    job: "Growth and Engagement Officer",
    image: "odunayo.jpg",
  },
  {
    id: 2,
    name: "Esther Odewumi",
    job: "Accounting & Training Operations Manager",
    image: "esther.jpg",
  },
  { id: 3, name: "Micheal Olawale", job: "ICT RID Associate", image: "micheal.jpg" },
  { id: 4, name: "Esther Okunola", job: "People Operations Manager", image: "estherokunola.jpg" },
  {
    id: 5,
    name: "Feranmi Oyeyemi",
    job: "Digital Marketer",
    image: "feranmi.jpg",
  },
  { id: 6, name: "Mary Taiwo", job: "Digital Marketer", image: "mary.jpg" },
  {
    id: 7,
    name: "Joshua Ajao",
    job: "Data Scientist",
    image: "joshua.jpg",
  },
  {
    id: 8,
    name: "Timileyin Akinwale",
    job: "UI/UX Specialist",
    image: "timileyin.jpg",
  },
  {
    id: 9,
    name: "Temitope Adisa",
    job: "UI/UX Specialist",
    image: "temitope.jpg",
  },
  {
    id: 10,
    name: "Deborah Ayoola",
    job: "UI/UX Designer",
    image: "ayoola.jpg",
  },
  {
    id: 11,
    name: "Mercy Arawole",
    job: "Lead Product Designer & Cyber Security Analyst",
    image: "mercy.jpg",
  },
  {
    id: 12,
    name: "Elisha Oderinde",
    job: "Software Engineer (Mobile App)",
    image: "elishaa.jpg",
  },
  {
    id: 13,
    name: "Ifeoluwa Adaralegbe",
    job: "Software Engineer (Web)",
    image: "ifeoluwa.jpg",
  },
  {
    id: 14,
    name: "Ayomide Olajide",
    job: "Software Engineer (Web)",
    image: "ayomide.jpg",
  },
  {
    id: 15,
    name: "Segun Adebiyi",
    job: "IOT Engineer",
    image: "segun.jpg",
  },
  {
    id: 16,
    name: "Abdullahi Ademola",
    job: "Frontend Engineer (Web)",
    image: "abdullahi.jpg",
  },
  {
    id: 17,
    name: "Paul Kolapo",
    job: "Software & DevOps Engineer",
    image: "paul.jpg",
  },
  {
    id: 18,
    name: "Adekola Kolapo",
    job: "Frontend Engineer (Web)",
    image: "kolapo.jpg",
  },

  {
    id: 19,
    name: "Felix Idowu",
    job: "Mobile App Engineer",
    image: "felix.jpg",
  },
  {
    id: 20,
    name: "Moses Aremu",
    job: "Mobile App Engineer",
    image: "moses.jpg",
  },
  {
    id: 21,
    name: "Favour Jewoola",
    job: "Mobile App Developer",
    image: "favour.jpg",
  },
  {
    id: 22,
    name: "Titilayo Olawumi",
    job: "Data Scientist",
    image: "titilayo.jpg",
  },
  // {
  //   id: 22,
  //   name: "Ayokunle Ola-Akande",
  //   job: "Software Engineer",
  //   image: "paul.jpg",
  // },

];

export default team;
