import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import { Slide } from "react-reveal";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AppButton from "../app-button/app-button.component";
import { Fragment } from "react";

import routes from "../../routes";
import BrandLogo from "../brand-logo/brand-logo.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Nav = () => {
  let [showNav, setShowNav] = useState(false);

  const handleNav = () => {
    setShowNav((showNav = !showNav));
  };

  const closeNav = () => {
    setShowNav(false);
  };

  let navigate = useNavigate();

  function goToHandler() {
    navigate("/courses");
    setShowNav((showNav = !showNav));
  }

  return (
    <Fragment>
      {/* <div className="absolute top-0 inset-x-0 z-20"> */}
      <div>
        <div className=" relative z-50 bg-white py-2">
          <nav className="flex justify-between container mx-auto px-0 md:px-10 items-center">
            <BrandLogo logoType="black" height="h-14" />
            <div
              className="lg:hidden absolute right-8 z-50"
              onClick={handleNav}
            >
              <Fade when={!showNav}>
                <button>
                  <MenuRoundedIcon />
                </button>
              </Fade>
            </div>
            <span className="menu hidden lg:flex items-center gap-x-12 md:gap-x-8">
              {routes.map((route) =>
                route.link.startsWith("http") ? (
                  <a
                    key={route.name}
                    href={route.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    {route.name}
                  </a>
                ) : (
                  <Link key={route.name} to={route.link} className="">
                    {route.name}
                  </Link>
                )
              )}

              {/* {routes.map((route) => (
                <Link key={route.name} to={route.link} className="">
                  {route.name}
                </Link>
              ))} */}
            </span>
            <div className="hidden md:inline-block mr-4">
              <AppButton
                children="Our Courses"
                buttonType="primary"
                handleButton={goToHandler}
              />
            </div>
          </nav>
        </div>
        {/* mobile nav */}
        {/* {showNav && ( */}
        <Slide left when={showNav}>
          <div
            className={`fixed inset-0 z-50 lg:hidden flex ${
              !showNav && "hidden"
            }`}
          >
            <div className="w-full px-12 py-10 bg-[#030A49] h-screen">
              <div className="flex justify-between mb-8">
                <BrandLogo logoType="white" height="h-12" onClick={handleNav} />
                <button className="text-white p-4" onClick={handleNav}>
                  <CloseRoundedIcon fontSize="large" />
                </button>
              </div>
              <ul className="mt-12">
                {routes.map((route) => (
                  <li
                    className={`mb-10 ${!showNav && "hidden"}`}
                    onClick={closeNav}
                    key={route.name}
                  >
                    {route.link.startsWith("http") ? (
                      <a
                        href={route.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white text-2xl font-medium"
                      >
                        {route.name}
                      </a>
                    ) : (
                      <Link
                        to={route.link}
                        className="text-white text-2xl font-medium"
                      >
                        {route.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>

              {/* <ul className="mt-12">
                {routes.map((route) => (
                  <li
                    className={`mb-10 ${!showNav && "hidden"}`}
                    onClick={closeNav}
                    key={route.name}
                  >
                    <Link
                      to={route.link}
                      className="text-white text-2xl font-medium"
                    >
                      {route.name}
                    </Link>
                  </li>
                ))}
              </ul> */}
              {showNav && (
                <div className="md:hidden">
                  <AppButton
                    children="Our Courses"
                    buttonType="secondary"
                    handleButton={goToHandler}
                  />
                </div>
              )}
            </div>
            {/* <div
              className={`bg-black w-2/5 h-screen opacity-25`}
              onClick={handleNav}
            ></div> */}
          </div>
        </Slide>
        {/* )} */}
      </div>
      <div className="">
        <Outlet />

        <div className="fixed bottom-16 right-8 z-50 p-2 text-2xl bg-green-900 rounded-md cursor-pointer">
          <a
            href="https://wa.link/5z5f9q"
            target="_blank"
            className="text-white"
            rel="noreferrer"
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M92.1 254.6c0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6L152 365.2l4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8c0-35.2-15.2-68.3-40.1-93.2c-25-25-58-38.7-93.2-38.7c-72.7 0-131.8 59.1-131.9 131.8zM274.8 330c-12.6 1.9-22.4 .9-47.5-9.9c-36.8-15.9-61.8-51.5-66.9-58.7c-.4-.6-.7-.9-.8-1.1c-2-2.6-16.2-21.5-16.2-41c0-18.4 9-27.9 13.2-32.3c.3-.3 .5-.5 .7-.8c3.6-4 7.9-5 10.6-5c2.6 0 5.3 0 7.6 .1c.3 0 .5 0 .8 0c2.3 0 5.2 0 8.1 6.8c1.2 2.9 3 7.3 4.9 11.8c3.3 8 6.7 16.3 7.3 17.6c1 2 1.7 4.3 .3 6.9c-3.4 6.8-6.9 10.4-9.3 13c-3.1 3.2-4.5 4.7-2.3 8.6c15.3 26.3 30.6 35.4 53.9 47.1c4 2 6.3 1.7 8.6-1c2.3-2.6 9.9-11.6 12.5-15.5c2.6-4 5.3-3.3 8.9-2s23.1 10.9 27.1 12.9c.8 .4 1.5 .7 2.1 1c2.8 1.4 4.7 2.3 5.5 3.6c.9 1.9 .9 9.9-2.4 19.1c-3.3 9.3-19.1 17.7-26.7 18.8zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM148.1 393.9L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5c29.9 30 47.9 69.8 47.9 112.2c0 87.4-72.7 158.5-160.1 158.5c-26.6 0-52.7-6.7-75.8-19.3z" />
          </svg> */}
            <FontAwesomeIcon icon="fa-brands fa-whatsapp" size="xl" />
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Nav;
